import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ListItem, UnorderedList, Box, IconButton,  HStack, Image, Link, SimpleGrid  } from "@chakra-ui/react";
import Text from '../SubHeading/SubHeading';
import { ChevronDownIcon } from "@chakra-ui/icons";

function MenuList(props) {
    // const menuLists = [
    //     {
    //         title: "LOCATIONS",
    //         link: "/windmill-lane",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "Windmill Lane",
    //                 link: "/windmill-lane",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "Lennox Street",
    //                 link: "/lennox-street",
    //                 targetBlank: false,
    //             },

    //         ],
    //     },
    //     {
    //         title: "STUDIOS",
    //         link: "/fitness-classes-dublin",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "Tread & Shred",
    //                 link: "/tread-and-shred",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "Rhythm Ride",
    //                 link: "/spin-class-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "Ride 45",
    //                 link: "/spin-studio-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "RUMBLE",
    //                 link: "/boxing-class-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "First Timers",
    //                 link: "/first-timers",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "TIMETABLE",
    //                 link: "/timetable",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PRICING",
    //                 link: "/pricing/studios",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "CROSSFIT",
    //         link: "/crossfit-dublin",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "GET STARTED",
    //                 link: "/crossfit-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "Beginners",
    //                 link: "/crossfit-dublin/beginners",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "Visiting? Drop In",
    //                 link: "/crossfit-dublin/drop-in",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "TIMETABLE",
    //                 link: "/timetable",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PRICING",
    //                 link: "/pricing/crossfit",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "JUSTGYM",
    //         link: "/luxury-gym-dublin-city-centre",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "Get Started",
    //                 link: "/luxury-gym-dublin-city-centre",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PRICING",
    //                 link: "/pricing/just-gym",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "PERSONAL TRAINING",
    //         link: "/personal-training-dublin",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "PERSONAL TRAINING",
    //                 link: "/personal-training-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "NUTRITION COACHING",
    //                 link: "/nutrition-coaching-dublin",
    //                 targetBlank: false,
    //             },
    //             // {
    //             //     title: "Golf",
    //             //     link: "/golf-fitness-training-dublin",
    //             //     targetBlank: false,
    //             // },
    //             {
    //                 title: "Pricing",
    //                 link: "/pricing/personal-training",
    //                 targetBlank: false,
    //             },
                
    //         ],
    //     },
    //     {
    //         title: "LADIES",
    //         link: "",
    //         targetBlank: false,
    //         submenu: [
    //             // {
    //             //     title: "Ladies Group",
    //             //     link: "/ladies-gym-dublin",
    //             //     targetBlank: false,
    //             // },
    //             {
    //                 title: "PRE/POST NATAL",
    //                 link: "/pregnancy-exercise-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PRICING",
    //                 link: "/pricing/prepost-natal",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "HYROX" ,
    //         link: "/hyrox-dublin",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "GET STARTED",
    //                 link: "/hyrox-dublin",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "TIMETABLE",
    //                 link: "/timetable",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PRICING",
    //                 link: "/pricing/hyrox",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "ONLINE TRAINING",
    //         link: "/online-gym-programming",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "ONLINE PROGRAMMING",
    //                 link: "online-gym-programming",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "PERPETUA TRAINING",
    //                 link: "https://perpetuatraining.com/",
    //                 targetBlank: true,
    //             },
    //              {
    //                 title: "PRICING",
    //                 link: "/pricing/online-programming",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    //     {
    //         title: "MEDIA",
    //         // link: "https://www.youtube.com/@perpetuafitness",
    //         link:"/blog",
    //         targetBlank: false,
    //         submenu: [
    //             {
    //                 title: "PODCAST",
    //                 link: "/podcast",
    //                 targetBlank: false,
    //             },
    //             {
    //                 title: "MOVEMENT LIBRARY",
    //                 link: "https://youtube.com/@perpetuafitness",
    //                 targetBlank: true,
    //             },
    //             {
    //                 title: "BLOG",
    //                 link: "/blog",
    //                 targetBlank: false,
    //             },
    //         ],
    //     },
    // ];

     const menuLists = [
        {
            title: "STUDIOS",
            link: "/windmill-lane",
            targetBlank: false,
            submenu: [
                {
                    title: "Windmill Lane",
                    link: "/windmill-lane",
                    subTitle: "D02 kX66",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/gps.png",
                },
                {
                    title: "Lennox Street",
                    link: "/lennox-street",
                    subTitle: "D02 FK02",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/gps.png",
                },
            ],
        },
        {
            title: "WORKOUTS",
            link: "/fitness-classes-dublin",
            targetBlank: false,
            submenu: [
                {
                    title: "Tread & Shred",
                    link: "/tread-and-shred",
                    subTitle: "Group fitness experience ",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/gym.png",
                },
                {
                    title: "Rhythm Ride",
                    link: "/spin-class-dublin",
                    subTitle: "Spin Class Dublin",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/health.png",
                },
                {
                    title: "Ride 45",
                    link: "/spin-studio-dublin",
                    subTitle: "Spin Class Dublin",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/health.png",
                },
                {
                    title: "RUMBLE BOXING",
                    link: "/boxing-class-dublin",
                    subTitle: "Boxing Class Dublin",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/boxing.png",
                },
                {
                    title: "CROSSFIT",
                    link: "/crossfit-dublin",
                    subTitle: "Ireland's First CrossFit Affiliate",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/weight-lifting.png",
                },
                {
                    title: "HYROX",
                    link: "/hyrox-dublin",
                    subTitle: "Ireland's First Hyrox Affiliate ",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/running-man.png",
                },
                {
                    title: "JUSTGYM",
                    link: "/luxury-gym-dublin-city-centre",
                    subTitle: "Premium fitness facility",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/weights.png",
                },
                {
                    title: "ONLINE PROGRAMMING",
                    link: "/online-gym-programming",
                    subTitle: "Start online training",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/weight.png",
                },
                {
                    title: "PRE/POST NATAL",
                    link: "/pregnancy-exercise-dublin",
                    subTitle: "Train with qualified coaches",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/pregnant.png",
                },
            ],
        },
        {
            title: "ONE TO ONE",
            link: "/personal-training-dublin",
            targetBlank: false,
            submenu: [
                {
                    title: "PERSONAL TRAINING",
                    link: "/personal-training-dublin",
                    subTitle: "Guaranteed Results",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/lifting.png",
                },
                {
                    title: "NUTRITION COACHING",
                    link: "/nutrition-coaching-dublin",
                    subTitle: "Learn the fundamentals",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/nutrition.png",
                },
                {
                    title: "INBODY ANALYSIS",
                    link: "/inbody",
                    subTitle: "Precise Health Scan",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/body-scan.png",
                },
                
            ],
        },
        {
            title: "COMMUNITY",
            link: "/blog",
            targetBlank: false,
            submenu: [
                {
                    title: "BLOG",
                    link: "/blog",
                    subTitle: "Read our latest news",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/blogger.png",
                },
                {
                    title: "PODCAST",
                    link: "/podcast",
                    subTitle: "Stay up to date",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/podcast.png",
                },
                {
                    title: "TEAM",
                    link: "/team",
                    subTitle: "Meet our coaches",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/group-chat.png",
                },
                // {
                //     title: "OUR STORY",
                //     link: "/story",
                //     subTitle: "Our values and culture",
                //     targetBlank: false,
                //     image: "./images/megaMenuIcons/hiring.png",
                // },
                
                {
                    title: "Movement Library",
                    link: "https://www.youtube.com/@perpetuafitness",
                    subTitle: "Watch our latest content",
                    targetBlank: true,
                    image: "./images/megaMenuIcons/stretching-exercises.png",
                },
                  {
                    title: "Testimonials",
                    link: "/perpetua-testimonials",
                    subTitle: "What Our Members Say",
                    targetBlank: false,
                    image: "./images/megaMenuIcons/satisfaction.png",
                },
            ],
        },
        {
            title: "FIRST TIMERS",
            link: "/first-timers",
            targetBlank: false,
        },
        
    ];
    const [menuId, setMenuId] = useState(null);
   
    const handelSubmenu = (str) => () => {
        if (menuId == str) {
            setMenuId(null);
        } else {
            setMenuId(str);
        }


};  

    return (
        <>
            <UnorderedList p="0" m="0" className="menu-body" height={{lg:'100%'}} display={{lg:'flex'}} flexWrap={{lg:"wrap"}}>
                {menuLists.map((item, index) => {
                    return (
                        <ListItem
                            key={index}
                            listStyleType="none"
                            fontSize={{ base: "18px", md: "18px", lg: "14px", xl: "18px" }}
                            display={{ md: "block", lg: "flex" }}
                            marginBottom={{ sm: "15px", md: "0" }}
                            padding={{ sm: "10px 0", lg: "0 3px", xl: "0 8px" }}
                            alignItems={{ lg: "center" }}
                        >
                            <Box className="menuDdBtn">                                 
                                 {
                                    item.targetBlank == true ? 
                                        <Link
                                        target={item.targetBlank ? "_blank" : ""} 

                                        className="nav-link"
                                        onClick={props.closeDrawer}
                                        href={item.link}
                                        >
                                            {item.title}
                                        </Link>
                                        : item.link == "" ?
                                        <Link
                                            className="nav-link"
                                            onClick={props.closeDrawer}
                                            to={item.link}
                                        >
                                            {item.title}
                                        </Link>
                                        : 
                                        <NavLink
                                            target={item.targetBlank ? "_blank" : ""}                                      

                                            className="nav-link"
                                            onClick={props.closeDrawer}
                                            to={item.link}
                                        >
                                            {item.title}
                                        </NavLink>
                                } 
                                {item.submenu &&
                                    <IconButton
                                        onClick={handelSubmenu(index)}
                                        className="ddIBtn"
                                        aria-label="Search database"
                                        icon={<ChevronDownIcon />}
                                    />
                                }
                            </Box>
                            { item?.submenu && <SimpleGrid
                                columns={{ sm: "1", md: "4" }}
                                className={
                                    index == menuId ? "menuDropdown open" : "menuDropdown "
                                }
                            >
                                {item?.submenu.map((subitem, subindex) => {
                                    return (
                                        subitem.targetBlank == true ? 
                                        <Link
                                        target={subitem.targetBlank ? "_blank" : ""}
                                        key={"sb" + subindex}
                                        className="nav-link"
                                        onClick={props.closeDrawer}
                                        href={subitem.link}
                                        >
                                            <HStack  alignItems="flex-start">
                                                <Image src={subitem?.image?subitem?.image:'./images/megaMenuIcons/pti.png'} width='18px' paddingTop='9px' />
                                                <Box paddingLeft='10px'>
                                                    {subitem.title}
                                                    <Text title={subitem.subTitle} fontSize='12px' textTransform='capitalize' color='#ccc' />
                                                </Box>
                                           </HStack>
                                        </Link>
                                        :
                                        <NavLink
                                            target={subitem.targetBlank ? "_blank" : ""}
                                            key={"sb" + subindex}
                                            className="nav-link"
                                            onClick={props.closeDrawer}
                                            to={subitem.link}
                                        >
                                           <HStack  alignItems="flex-start">
                                                <Image src={subitem?.image?subitem?.image:'./images/megaMenuIcons/pti.png'} width='18px' paddingTop='9px' />
                                                <Box paddingLeft='10px'>
                                                    {subitem.title}
                                                    <Text title={subitem.subTitle} fontSize='12px' textTransform='capitalize' color='#ccc' />
                                                </Box>
                                           </HStack>
                                        </NavLink>
                                    );
                                })}
                           </SimpleGrid> }
                        </ListItem>
                    );
                })} 
            </UnorderedList>
        </>
    );
}
export default MenuList;
