import React from 'react';
import {Flex, Wrap,WrapItem,Container, SimpleGrid, Box,Text} from '@chakra-ui/react';
import{NavLink} from 'react-router-dom';
import Link from '../LinkComponent/Link';
import Heading from '../Heading/Heading';
import { FaFacebook,FaInstagram,FaTiktok,FaYoutube, FaLinkedinIn} from "react-icons/fa";
import {
    ListItem,
    UnorderedList,
} from '@chakra-ui/react'
import ModalSignUp from '../Modal/ModalSignUp';
import Login from '../Login/Login';

function Footer() {
    return (
        <Flex alignContent="space-between" width={"full"} justifyContent={"center"} padding={{ sm:"50px 10px 15px", lg:"105px 50px"}} background={'black'}>
            <Container maxW='container.xl'>
                <Wrap width={"100%"} justify='left' alignItems={"top"} >
                    <WrapItem 
                    maxW={{base:'100%', md:'100%', lg:"20%",xl:"24%"}}
                    width={{base:'100%', md:'100%', lg:"20%",xl:"24%"}}  
                    flex="1 1 auto"  
                    textAlign={{md:'left',lg:"left", xl:"left"}}  
                    justifyContent={"left"}
                    flexDirection={{base:'column' ,md:'column'}}
                    p={{md:'20px 0px', lg:'0px'}}
                    display={{sm:'flex', lg:'flex'}}
                    >
                        <Link href='/' as={"h2"} color={"white"} lineHeight="26px"  title=" PERPETUA" fontSize={{sm:'42px', md:'40px'}} margin={{sm:'0 0 25px', md:'0px auto 20px 0px'}} ></Link>
    
                        <Heading display={{sm:'none', lg:'block'}} title="MAKE YOUR MOVE" lineHeight={"28px"} variant="small" margin={{sm:'0px auto', md:'0px auto 0px 0px' }}width={{md:'100%',lg:"80%", xl:"70%"}} color={"white"}/>
                        <Text as='p' paddingTop={'62px'} fontSize="14px" fontFamily='poppins' color={"white"} display={{base:'none',lg:'block'}}>All rights reserved © perpetuadublin 2023.<br/> Powered by : <Link href='https://infotechsolz.com' as={"a"} title='Infotechsolz' targetBlank={true}></Link> </Text>
  
                    </WrapItem>
  
                     <WrapItem 
                      maxW={{base:'100%', md:'100%', lg:"55%",xl:"52%"}}
                      width={{base:'100%', md:'100%', lg:"50%",xl:"52%"}}
                      flex="1 1 auto"  
                      textAlign={{base:"center", sm:"left",}}  
                      paddingLeft={{base:'0', md:'0px',lg:'30px',}}
                      justifyContent={"start"}
                      flexDirection={{sm:'column' ,md:'column'}}
                      alignItems='top'
                      
                    >
                          
                        <SimpleGrid columns={{base:'1', sm:'2', md:'3'}} spacing={6} width="100%" >
                            <Box>
                                <Heading as='h4' title="ABOUT" lineHeight={"1"}  cfSize={{sm:'20px', md:'25px'}} color={"white"}/>
                                <UnorderedList p='0' m='0'marginTop='15px' textTransform='uppercase'>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/blog" >BLOG</NavLink>
                                    </ListItem> */}
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/prepostnatal">POST NATAL</NavLink>
                                    </ListItem> */}
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/team">Team</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/work-for-perpetua">WORK FOR PERPETUA</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink  to="/story" >OUR STORY</NavLink>
                                    </ListItem>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/corporate-wellness" >CORPORATE</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >

                                        <NavLink className="" to="/residential-services"  >Residential Services</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/private-gym-hire-dublin"  >Private Hire</NavLink>
                                    </ListItem> */}
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/mojo">MOJO</NavLink>
                                    </ListItem>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/nutrition"  >NUTRITION</NavLink>
                                    </ListItem> */}
                                    
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/application" >APPLICATION</NavLink>
                                    </ListItem> */}
                                    
                                </UnorderedList>
                            </Box>
                            <Box >
                                <Heading as='h4' title="QUICK LINK" lineHeight={"1"}  cfSize={{sm:'20px', md:'25px'}} color={"white"}/>
                                <UnorderedList p='0' m='0'marginTop='15px' textTransform='uppercase'>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/perpetua-testimonials">Testimonials</NavLink>
                                    </ListItem> */}
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/membership">MEMBERSHIP</NavLink>
                                    </ListItem>
                                    
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/hyrox">HIGHROX</NavLink>
                                    </ListItem> */}
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/pricing">PRICING</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/timetable">Timetable</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <Link title='Download App'  href="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131" targetBlank={true} ></Link>
                                    </ListItem>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/inbody">InBody Analysis</NavLink>
                                    </ListItem> */}
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/sign-up">Create Account</NavLink>
                                    </ListItem>
                                    {/* <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <Login/>
                                    </ListItem> */}
                                </UnorderedList>
                            </Box>
                            <Box>
                                <Heading as='h4' title="CONTACT" lineHeight={"1"}  cfSize={{sm:'20px', md:'25px'}} color={"white"}/>
                                <UnorderedList p='0' m='0' marginTop='15px'>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink  to="/windmill-lane">WINDMILL LANE</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink  to="/lennox-street">LENNOX STREET</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <NavLink className="" to="/contact-us">CONTACT US</NavLink>
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs', sm:'15px'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <Link className="" title='HELLO@PERPETUA.IE' href="mailto:hello@perpetua.ie" > </Link>
    
                                    </ListItem>
                                    <ListItem listStyleType='none' fontSize={{base:'xs'}} display={{md:'block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} >
                                        <Link className="" title='01 524 26 35' href="tel:01 524 26 35"> </Link>                    
                                    </ListItem>              
                                </UnorderedList>
                            </Box>
                            <Box display={{sm:'block', md:'none'}}>
                                <Heading  title="MAKE YOUR MOVE" cfSize={{sm:'25px', md:'30px'}} margin={{sm:'-5px', md:'0px' }}width={{sm:'100%' ,md:'100%'}} color="white"/>
                            </Box>
                        </SimpleGrid>
                    </WrapItem>
  
                    <WrapItem 
                        maxW={{base:'100%', md:'100%',lg:'20%' ,xl:"20%"}}
                        width={{base:'100%', md:'100%', lg:"20%",xl:"20%"}}
    
                        flex="1 1 auto"  
                        textAlign={{lg:"right", xl:"right"}}  
                        justifyContent={"start"}
                        flexDirection={{sm:'column', md:'column', lg:'column'}}
                        alignItems='top'
                    >
                        <Box maxWidth={'100%'} width='100%' textAlign={{sm:'right' ,md:'right',lg:'right'}}>
                            <Heading as='h4' title="GET SOCIAL" lineHeight={"1"} variant="extrasmall" color={"white"}/>
                            <UnorderedList p='0' m='0' marginTop='15px' textAlign={{md:'right',lg:'right'}}>
                                <ListItem listStyleType='none' fontSize={{base:'22px'}} display={{base:'inline-block'}} paddingBottom='10px' marginRight='10px'  color={"white"} _hover={{color:'Red'}} >
                                    <Link className="" title={<FaFacebook />} href="https://www.facebook.com/perpetuafitnessdublin" targetBlank={true}>
                                    
                                    </Link>
                                </ListItem>
                                <ListItem listStyleType='none' fontSize={{base:'22px'}} display={{base:'inline-block'}} paddingBottom='10px' marginRight='10px' color={"white"} _hover={{color:'Red'}}  >
                                    <Link className="" title={<FaInstagram/>} href="https://www.instagram.com/perpetuafitnessdublin/" targetBlank={true} >
                                    </Link>
                                </ListItem>
                                <ListItem listStyleType='none' fontSize={{base:'22px'}} display={{base:'inline-block'}} paddingBottom='10px' marginRight='10px' color={"white"} _hover={{color:'Red'}} >
                                    <Link className="" title={ <FaTiktok/>} href="https://www.tiktok.com/@perpetuafitnessdublin" targetBlank={true}>
                                    
                                    </Link>
                                </ListItem>
                                <ListItem listStyleType='none' fontSize={{base:'22px'}} display={{base:'inline-block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} marginRight='10px' >
                                    <Link className="" title={<FaYoutube/>} href="https://www.youtube.com/@perpetuafitness" targetBlank={true} ></Link>
                                </ListItem>
                                <ListItem listStyleType='none' fontSize={{base:'22px'}} display={{base:'inline-block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} marginRight='10px' >
                                    <Link className="" title={<FaLinkedinIn/>} href=" https://ie.linkedin.com/company/perpetuafitness" targetBlank={true} ></Link>
                                </ListItem>
                            </UnorderedList>
                          
                        </Box>
  
                        <Box marginTop={{sm:'16px' ,lg:'22px'}} maxWidth={'100%'} width='100%' textAlign={'right'}>
                             <ModalSignUp/>
                            <Link href='/' as={"h2"} color={"white"} lineHeight="26px"  title=" PERPETUA" fontSize={{base:'sm', md:'md', lg:"lg",xl:"lg"}} margin={{md:'0px auto', lg:'0px auto 20px 0px'}}></Link>
                            <UnorderedList p='0' m='0'marginTop='15px' textAlign={'right'} >
                                <ListItem listStyleType='none' fontSize={{base:'12px'}} display={{base:'inline-block'}} paddingBottom='10px' marginRight={{sm:'10px', lg:'0px',xl:'10px'}}  color={"white"} _hover={{color:'Red'}} fontFamily='poppins' >
                                    <NavLink className="" to="/terms-condition">
                                        Terms & Conditions   •   
                                    </NavLink>
                                </ListItem>
                                <ListItem listStyleType='none' fontSize={{base:'12px'}} display={{base:'inline-block'}} paddingBottom='10px'color={"white"} _hover={{color:'Red'}} fontFamily='poppins'>
                                    <NavLink className=""  to="/privacy-policy" >
                                        Privacy Policy
                                    </NavLink>
                                </ListItem>
                            </UnorderedList>
                           
                        </Box>
                    </WrapItem>
                    <Text 
                        as='p' 
                        paddingTop={'15px'} 
                        fontSize="14px" 
                        fontFamily='poppins' 
                        color={"white"} 
                        display={{md:'block',lg:'none'}}
                        textAlign={{base:'center',lg:'right'}}
                        width="100%"
                        borderTop={"1px solid #323232"}
                    >
                      All rights reserved © perpetuadublin 2023. Powered by : 
                      <Link href='https://infotechsolz.com' as={"a"} title='Infotechsolz' targetBlank={true}></Link> 
  
                    </Text>
                 </Wrap>
            </Container>
        </Flex>
    );
  }
  
  export default Footer;


