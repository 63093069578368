import React from 'react';
import * as Style from './CorporateCard.style';
import {Container} from '@chakra-ui/react';

const CorporateimageBox  = ( props ) => {
  return (
  <> 
    <Style.CorporateimageBox backgroundImage={props.bg} {...props}>
      <Container maxW='container.xl'>
        {props.children}
      </Container>
    </Style.CorporateimageBox>
  </>
  );
}
export default CorporateimageBox;