import React from 'react';
import { Box, Flex, Text, Container, Link } from '@chakra-ui/react';
import Button from '../component/Button/CustomeButton';
import AppDownload from '../component/AppDownload/AppDownload';
import ClassListing from '../component/ClassListing/ClassListing';
import Heading from '../component/Heading/Heading';
// import{Link} from 'react-router-dom';
// import SlideCarousel from '../component/carousel/carousel';
import MetaTitle from './MetaTitle';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import * as Style from './Banner.style';

function Home() {

  const banners = [
    {
      "image": "url(/images/home_banner.jpg)",
      "subtitle": "MAKE YOUR MOVE",
      "title": "THE FITNESS EXPERIENCE YOU WON'T FORGET",
    }
  ]
  const metaTitle = "Perpetua Fitness | CrossFit Dublin | Make Your Move"
  const metaDesc = "The fitness experience you won't forget. Community and movement is at the heart of everything we do here at Perpetua Fitness. Make your move."


  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
      {/* when temporary Landing page needed open banner and coment slider carousel banner */}
      {/* <SlideCarousel banners={banners} />  */}

      {/* <Style.LandindBanner backgroundImage={{ sm: 'url(./images/HomepageBannerMb3.webp)', md: 'url(./images/lulu-home-banner.webp)' }}>
        <Container maxW='container.xl'>
          <Style.BannerWrapper>
            <Style.ButtonWrapper>
              <Flex direction={{ sm: 'column', md: 'row'}} gap='15px' width='100%'
                justifyContent={{ md: 'center' }} alignItems='center'>
                <Style.Button to='/unbroken-powered-by-lululemon'>Find Out More</Style.Button>
                <Style.externalBtn isExternal={true} href='https://cart.mindbodyonline.com/sites/76074/cart'>Sign up Now</Style.externalBtn>
              </Flex>
            </Style.ButtonWrapper>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}
      
      {/* <Style.LandindBanner backgroundImage={{ sm: 'url(./images/HomepageBannerMb3.webp)', md: 'url(./images/lulu-home-banner.webp)' }}>
        <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 30px", lg: "0 70px", xxl:"0 130px" }}>
          <Style.BannerWrapper>
            <Flex alignItems={{ md: 'center' }} width="100%" gap='15px' position="absolute" left={{sm:'0px', md:"0"}} top={{ md: "0" }} bottom={{sm:'70px', md:"0"}}>
              <Box width="100%">
                <Heading textAlign={{sm:"center", md:"left"}} title=''  color='#fff' variant="smallmid"></Heading>
                <Heading color='#fff' textAlign={{sm:"center", md:"left"}}  title="" width={{ sm: "100%", md: "50%", lg:"50%", xl:"60%", xxl:"50%" }} lineHeight="1"  variant="largheading" ></Heading>
                <Flex direction={{ sm: 'column', md: 'row' }} gap='15px' marginTop='20px'
                  justifyContent={{ sm: 'center', md: "flex-start" }} alignItems={{ sm: 'center', md: "flex-start" }}>
                  <Style.Button to='/unbroken-powered-by-lululemon'>Find Out More</Style.Button>
                <Style.externalBtn isExternal={true} href='https://cart.mindbodyonline.com/sites/76074/cart'>Sign up Now</Style.externalBtn>
                </Flex>
              </Box>
            </Flex>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}

       {/* <Style.LandindBanner backgroundImage={{ sm: 'url(./images/mobile._screenHomeBanner.webp)', md: 'url(./images/HomepageSummerClosingParty.webp)' }}>
        <Container maxW='container.2xl' p={{ sm: '0 20px', md: "0 30px", lg: "0 70px", xxl:"0 130px" }}>
          <Style.BannerWrapper>
            <Flex alignItems={{ md: 'center' }} width="100%" gap='15px' position="absolute" left={{sm:'0px', md:"0"}} top={{ md: "0" }} bottom={{sm:'70px', md:"0"}}>
              <Box width="100%">
                <Heading textAlign={{sm:"center", md:"left"}} title=''  color='#fff' variant="smallmid" />
                <Heading color='#fff' textAlign={{sm:"center", md:"left"}}  title="" width={{ sm: "100%", md: "50%", lg:"50%", xl:"60%", xxl:"50%" }} lineHeight="1"  variant="largheading" />
                <Style.ButtonWrap>
                  <Style.Button to='/summer-closing-party'>Purchase Now</Style.Button>
                  <Style.externalBtn href='/timetable'>Book a Class</Style.externalBtn>
                </Style.ButtonWrap>
              </Box>
            </Flex>
          </Style.BannerWrapper>
        </Container>
      </Style.LandindBanner> */}

      <VideoBanner
        // videobanner='../video/Home-banner.mp4'
        videobanner='../video/Home-banner1.mp4'
        title="THE FITNESS EXPERIENCE YOU WON'T FORGET"
        subtitle="MAKE YOUR MOVE"
        isButton={true}
        issubtitleRight={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://youtube.com/embed/2ukYI8KIwUk?si=fKCNCc7rXezitDjQ'
        targetBlank={false}
        modalButtontitle="GO INSIDE PERPETUA"
      />

      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        background={"black"}
      >
        <Container maxW='container.xl'>
          <Box
            width="90%"
            p={{ base: '20px 0px', sm: '30px 0px', md: '50px 0px' }}
            textAlign={"center"}
            color={"white"}
            textTransform="uppercase"
            m={"auto"}
          >
            {/*     <Heading 
                    color="white" 
                    variant="medium"
                    title="Community and movement is at the heart of"
                    lineHeight='1'
                ></Heading> */}
            <Heading
              color="white"
              variant="medium"
              title="Community and movement is at the heart of everything we do here at Perpetua. We live for intelligent programming, functional fitness and having a good time together."
              ineHeight='1'
              lineHeight={{ sm: ".9", md: "1.3" }}
              className='wow animate fadeInUp'
            >
            </Heading>
            <Text
              className='wow animate fadeInUp'
              fontSize={{ sm: "16px", md: "24px" }}
              as="p"
              fontFamily='Poppins'
              marginTop='20px'
              marginBottom='20px'
              color='white'
              fontWeight='300'
            >
              <Text as="b">Make</Text> Your Move</Text>
            <Link href="/timetable">
              <Button title='BOOK A CLASS' className='wow animate fadeInUp' />
            </Link>
          </Box>
        </Container>
      </Flex>

      <ClassListing />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading='Your Daily Dose of Online Workouts Is Live'
        para='Perpetua Online Programming'
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - perpetua fitness"
      />

      <VideoSection heading='Check out'
        headingSub='our studios' video1='https://youtube.com/embed/2ukYI8KIwUk?si=xs2r3j2jyf_Io-uW'
        video2='https://youtube.com/embed/wuxj2I_lYc0?si=zTjaSl4eJ5cWedbo' smallHeading='Windmill Lane' smallHeading2='Lennox Street' midHeading='' midHeading2=''
        borderB='1px solid #232323' borderT='1px solid #232323'
      />

      {/* <Flex position='relative'
          backgroundImage="url('/images/Perpetua Shop.jpg')"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          minHeight={{ sm:'450px', md:'500px', lg:'550px', xl:'630px'}}
          backgroundSize="cover"
          padding={{sm:"50px 0", md:"60px 0"}}
          className="shopSection"
        
        >
            <SimpleGrid columns={{sm:'1',md:'2'}} columnGap={10} rowGap={2} width="full">

                <GridItem pl={{sm:'0', md:'50px',lg:'50px', xl:'306px'}} paddingTop={{sm:'50px', md:'0',lg:'0', xl:'0'}} paddingBottom={{sm:'50px', md:'0',lg:'0', xl:'0'}} display='flex' alignItems={{sm:"flex-end", md:'center'}} className='shop_now'>
                    <Box width={{ sm:'100%', lg:'95%', xl:'80%'}} textAlign={{sm:'left', md:"left" }}>
                        <Text color="#fff" textAlign={{sm:'left', md:"left" }} as="p" fontFamily="poppins" fontSize="sm2" paddingBottom='15px' textTransform='uppercase'
                        className="wow fadeInUp" 
                        >Perpetua Shop</Text>
                        <Heading 
                        color='#fff'
                          lineHeight={"1"} 
                          textAlign={{sm:'left', md:"left" }}
                          as="h2" 
                          textTransform='uppercase'
                          marginBottom='30px' 
                          title="Check out our Latest Drops"
                          variant='extralarge'
                          className="wow fadeInUp" 
                          ></Heading>
                        <Link href="/shop" _hover="textDecoration:none"  >
                            <Button title='View Shop' color='#fff' className="wow fadeInUp" />
                        </Link>
                    </Box>
                </GridItem>

                <GridItem>
                    <Box
                    className='bottom_img' 
                    maxW="full" 
                    alignItems="center" 
                    textAlign={'center'}
                    p='20px'
                    backgroundImage="url('/images/team.jpg')"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    minHeight={{ sm:'450px', md:'500px', lg:'550px', xl:'630px'}}
                    backgroundSize={"cover"}
                    position="relative"
                    >

                    </Box>
                </GridItem>
            </SimpleGrid>
        </Flex> */}
    </>
  );
}

export default Home;
