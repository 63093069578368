import React, {useEffect} from 'react';
import {Container,Box,SimpleGrid, Flex,Text} from '@chakra-ui/react';
import JointeamCard from '../component/JoinTeamCard/JointeamCard';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from '../component/Heading/Heading';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';

function WorkforPerpetua(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title='Work for perpetua | Perpetua Fitness'/>
      <InnerBanner 
        backgroundColor='black' 
        backgroundImage={{sm:'url(./images/work_at_perpetua_m.jpg)', md:'url(./images/work_for_perpetua_banner.png)'}} 
        subtitle={false}
        title='WORK AT PERPETUA'
        subtitleRight='The Best Studio Experience In Ireland'
        subtextpara='Want to work with a team that focuses on career progression? Look no further'
        isButton={false} 
        issubtitleRight={false} 
        issubtextpara={true}
        width="100%"
        height={{sm:'400px', md:'400px', lg:'460px', xl:'460px'}}
        align='0px auto'
        textAlign='center'
        maxW = {{sm:"100%", md:"800px"}}
        />
        <Container maxW='container.xl'  padding={{sm:'100px 15px'}}>
          <SimpleGrid columns={{base:'1',md:'2',lg:'4'}} spacing={6} >
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box1.jpg')" 
                //subtitle='Join Team Perpetua' 
                heading='CROSSFIT COACH' 
                to='/crossfit-coach' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'               
              >
              </JointeamCard>
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box2.jpg')" 
                //subtitle='Join Team Perpetua' 
                heading='STUDIO COACH' 
                to='/treadandshred-instructor' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'
              >
              </JointeamCard>
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box3.jpg')" 
                //subtitle='Join Team Perpetua' 
                heading='FRONT OF HOUSE' 
                to='/font-of-house' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'
              >
              </JointeamCard>
            </Box>
            <Box>
              <JointeamCard 
                Imguri="url('/images/WFP-box4.jpg')" 
                //subtitle='Join Team Perpetua' 
                heading='FACILITIES' 
                to='/facilitics' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'             
              >
              </JointeamCard>
          </Box>
          {/* <Box>
              <JointeamCard 
                Imguri="url('/images/fontOfHouseLand3.webp')" 
                //subtitle='Join Team Perpetua' 
                heading='Front of House Lead' 
                to='/front-of-house-lead' 
                subheadingfontSize='17px'
                spacing='20px 0px 0px'
                linktitle='Read more'             
              >
              </JointeamCard>
            </Box> */}
          </SimpleGrid>
            
        </Container>
       
       
       
        {/* <Flex background='LightGrey'  marginBottom={{base:'50px',md:'100px'}} padding={{base:'50px 15px'}}>
        <Container maxW='container.xl'>
            <SimpleGrid columns={{base:'1',md:'2',lg:'4'}} spacing={6}>
              <Box>
                    <Heading variant='small' title='Personal trainer' as='h3'  margin='0px 0px 10px'></Heading>
                    <Link
                      to='/'
                      fontFamily='poppins'
                      textDecoration='underline'
                    >
                        Read More
                    </Link>
              </Box>
              <Box>
                    <Heading variant='small' title='Coach' as='h3'  margin='0px 0px 10px'></Heading>
                    <Link
                      to='/'
                      fontFamily='poppins'
                      textDecoration='underline'
                    >
                        Read More
                    </Link>
              </Box>
              <Box>
                    <Heading variant='small' title='Coach intern' as='h3'  margin='0px 0px 10px'></Heading>
                    <Link
                      to='/'
                      fontFamily='poppins'
                      textDecoration='underline'
                    >
                        Read More
                    </Link>
              </Box>
              <Box>
                    <Heading variant='small' title='Front Desk Rep' as='h3'  margin='0px 0px 10px'></Heading>
                    <Link
                      to='/'
                      fontFamily='poppins'
                      textDecoration='underline'
                    >
                        Read More
                    </Link>
              </Box>
            </SimpleGrid>
          </Container>
        </Flex> */}

        <Flex background='black' borderBottom='1px solid #232323' padding={{base:'100px 0px'}}>
          <Container maxW='container.xl'>
            <Box margin="0px auto" w='90%' textAlign='center'>
              <Heading as='h2' color="white" variant='medium' className='wow animate fadeInUp'  lineHeight='1.3' margin='0px 0px 52px' title='AT PERPETUA Community and movement is at the heart of everything we do here. We live for intelligent programming, functional fitness and having a good time together while sweating it out.'></Heading>
              <Text 
                    className='wow animate fadeInUp' 
                    fontSize={"2xl"} 
                    as="p" 
                    fontFamily='Poppins' 
                    marginTop='50px' 
                    marginBottom='50px'
                    color='white'
                    fontWeight='300'
                    textTransform='uppercase'
                > 
                <Text as="b" paddingRight='8px'>Make</Text> 
                Your Move
              </Text>
            </Box>
          </Container>
        </Flex>
    </>
  );
}

export default WorkforPerpetua;