import React from 'react';
import { Box, Button, Center, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';

const PriceCard3 = (props) => {
    return (
        <Box pos='relative' marginTop={{ sm: '15px', md: "0" }}>
            {props.cardTitle && <Heading lineHeight='0.9' title={props.cardTitle} variant='small' margin='0px 0px 30px' textAlign="center" />}
            <Box>
                {props.tagTitle && <Heading className='tag' variant='smallheading' as='small' lineHeight='0.9' title={props.tagTitle} ></Heading>}

                <Box border='3px solid #000' minH='270px' paddingBottom='10px'>
                    <Box backgroundColor="Red" borderTop='8px solid #000' padding='10px 0'>
                        <Heading title={props.planname} color='#fff' variant='extrasmall' textAlign='center' />
                    </Box>
                    <Box width={{ sm: "100%", md: "auto" }} padding='20px 0 0' >
                        <Heading title={props.currency} variant='medium' lineHeight='0.9' textAlign='center' />
                        <Text fontSize={{ sm: "14px", md: "16px" }} color="#7D7D7D" as='p' title={props.offer}colors='Grey' margin="5px 0 0" textAlign='center'></Text>
                    </Box>
                    <Link href={props.link} _hover="textDecoration:none" isExternal={props.targetBlank} >
                        <Center paddingTop='15px'>
                            <Button color='#fff' backgroundColor='#000' fontSize={{ sm: '12px', md: '16px', xl: '18px' }} textTransform='uppercase' width={{ sm: '100px', md: '150px', xl: '208px' }} minH='49px' _hover={{ bg: 'Red' }} >
                                {props.buttonTitle}
                            </Button>
                        </Center>
                    </Link>
                    <Text fontSize={{ sm: "14px", md: "16px" }} color="#7D7D7D" as='p' title={props.description} colors='Grey' width={props.width} margin="5px 0" textAlign='center' padding='0 10px'></Text>
                    {
                        props.listItem && <Center padding='10px 0' flexDirection='column'>
                        <UnorderedList>
                            <ListItem>
                                <Text fontSize={{ sm: "14px" }} color="#000" as='p' fontFamily='poppins' title='Membership Perks' />
                            </ListItem>
                            <ListItem>
                                <Text fontSize={{ sm: "14px" }} color="#000" as='p' fontFamily='poppins' title={props.guest + " Guest Pass/Month"} />
                            </ListItem>
                            <ListItem>
                                <Text fontSize={{ sm: "14px" }} color="#000" as='p' fontFamily='poppins' title={"Add'l Credits-" + props.credit +"/Credit"} />
                            </ListItem>
                        </UnorderedList>
                        <Box>
                            {props.icon && <Box color='#D12C41' marginRight='5px' display='inline-flex' ><i className={props.icon}></i></Box>}
                            {props.freeze && <Heading variant='smallheading' as='small' lineHeight='0.9' title={props.freeze}></Heading>}
                        </Box>
                    </Center>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default PriceCard3;